(function ($) {
    "use strict";

    $.fn.dropdown2 = function () {
        var elements = this;

        if (elements.length > 0) {
            elements.each(function(key, element) {
                createStructure($(element));
            });
        }
        // var $element = $(this.select);

        // $element.addClass('dropdown2-mounted');

        function createStructure(dropdownEl) {
            dropdownEl.addClass('dropdown2-mounted').attr('tabindex', '-1');
            var $outerElement = $('<div tabindex="0" class="form-control form-control-dropdown dropdown2-element"><span class="dropdown2-content"></span></div>');
            var $itemsOuterElements = $('<div class="dropdown2-items"><div class="dropdown2-items-inner"></div></div>');

            $outerElement.insertAfter(dropdownEl);
            $itemsOuterElements.insertAfter($outerElement);

            setCurrentOption(dropdownEl);

            var $options = dropdownEl.find('option');

            if ($options.length > 0) {
                $options.each(function(key, optionItem) {
                    $itemsOuterElements.find('.dropdown2-items-inner').append('<div class="dropdown2-item' + (dropdownEl.val() == $(optionItem).attr('value') ? ' selected' : '') + '" data-value="' + $(optionItem).attr('value') + '">' + ($(optionItem).attr('data-styled') ? $(optionItem).attr('data-styled') : $(optionItem).html()) + '</div>');
                });
            }

            bindEvent($outerElement, $itemsOuterElements);
        }

        function setCurrentOption(dropdownEl) {
            var $currentValue = dropdownEl.val();
            var $currentOption = dropdownEl.find('option[value="' + $currentValue + '"]');
            var $styledOption = $currentOption.length !== 0 ? ($currentOption.attr('data-styled') ? $currentOption.attr('data-styled') : $currentOption.html()) : null;
            var $dropdown2Content = dropdownEl.closest('.form-group').find('.dropdown2-content');

            if ($currentValue && $currentValue !== '') {
                $dropdown2Content.removeClass('placeholder').html($styledOption);
            } else {
                $dropdown2Content.addClass('placeholder').html($styledOption);
            }
        }

        function bindEvent(myElement, myElementItems) {
            myElement.closest('.input-group').on('click', function(e) {
                // e.preventDefault(); // i want focus
                // myElement.addClass('focused');
                myElementItems.toggleClass('open');
            });

            myElementItems.on('click', '.dropdown2-item', function(e) {
                var $value = $(this).attr('data-value');
                $(this).closest('.input-group').find('select').val($value);
                $(this).closest('.input-group').find('select option[value="' + $value + '"]').prop('selected', true);
                $(this).closest('.input-group').find('select').trigger('change');
                $(this).closest('.dropdown2-items').removeClass('open');
                setCurrentOption($(this).closest('.input-group').find('select'));
                $(this).closest('.input-group').find('select').prop('focus', true);
            });
        }

        $(document).on('change', '.dropdown2-mounted', function() {
            var $currentValue = $(this).val();
            $(this).closest('.input-group').find('.dropdown2-items .dropdown2-item').removeClass('selected');
            $(this).closest('.input-group').find('.dropdown2-items .dropdown2-item[data-value="' + $currentValue + '"]').addClass('selected');
        });

        $(document).on('click', function(e) {
            var $target = $(e.target);
            var $currentEl = null;
            var $currDropdown = null;

            if ($target.closest('.input-group').find('.dropdown2-element').length > 0 || ($target.hasClass('input-group') && $target.find('.dropdown2-element').length > 0)) {
                $currentEl = $target.hasClass('input-group') ? $target.find('.dropdown2-element') : $target.closest('.input-group').find('.dropdown2-element');

                if ($currentEl) {
                    $currDropdown = $currentEl.closest('.input-group').find('.dropdown2-items');
                }
            } else if ($target.hasClass('dropdown2-element') || $target.closest('.dropdown2-element').length > 0) {
                $currentEl = $target.hasClass('dropdown2-element') ? $target : $target.closest('.dropdown2-element');

                if ($currentEl) {
                    $currDropdown = $currentEl.closest('.input-group').find('.dropdown2-items');
                }
            }

            $(document).find('.dropdown2-items').not($currDropdown).removeClass('open');
        });
    }

    $.fn.dropdown2.defaults = {}

}(jQuery));

(function ($) {
    "use strict";

    $.fn.headerSearch = function () {
        var elements = this;

        const searchTemplate =
            '<div class="search-overlay">' +
                '<div class="search-outer">' +
                    '<div class="search-inner">' +
                        '<form>' +
                            '<div class="form-group form-group-md">' +
                                '<div class="input-group input-group-mg">' +
                                    '<a class="input-group-prepend cursor-pointer" data-search-submit>' +
                                        '<i class="input-group-text shz-icon shz-icon-search"></i>' +
                                    '</a>' +
                                    '<input type="text" id="page-search" class="form-control form-control-md" placeholder="Napíšte hľadaný pojem...">' +
                                    '<a class="input-group-append cursor-pointer" data-search-close>' +
                                        '<i class="input-group-text shz-icon shz-icon-x"></i>' +
                                    '</a>' +
                                '</div>' +
                            '</div>' +
                        '</form>' +
                    '</div>'+
                '</div>' +
            '</div>';

        $(document).on('click', '[data-search-show]', function() {
            $('body').addClass('window-disable-scroll');

            if ($(document).find('.search-overlay').length === 0) {
                const searchControl = $(searchTemplate);
                $('body').append(searchControl);
                searchControl.find('.form-control').focus();
            } else {
                $(document).find('.search-overlay').removeClass('d-none');
            }
        });

        $(document).on('click', '[data-search-submit]', function() {
            $(this).closest('form').submit();
        });

        $(document).on('click', '[data-search-close]', function() {
            $(document).find('.search-overlay').addClass('d-none');
            $('body').removeClass('window-disable-scroll');
        });
    }

    $.fn.headerSearch.defaults = {}

}(jQuery));

$(function() {
    $(document).find('.form-control-dropdown').dropdown2();
    $(document).headerSearch();

    $('.owl-carousel').owlCarousel({
        loop: true,
        margin: 32,
        nav: true,
        navText: ["<span class='shz-icon shz-icon-arrow-left shz-icon-size-xl'></span>", "<span class='shz-icon shz-icon-arrow-right shz-icon-size-xl'></span>"],
        navElement: 'button',
        navContainer: false,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        items: 1.75,
        dots: false,
        responsiveClass:true,
        autoWidth: false,
        responsive : {
            0 : {
                items: 1,
            },
            992: {
                items: 1.30,
            },
            1200 : {
                items: 1.75,
            }
        }
    });

    $('[data-datepicker]').datepicker({
        format: "dd.mm.yyyy",
        startView: 2,
        language: "sk",
        orientation: "bottom left",
        autoclose: true,
        templates: {
            leftArrow: '<i class="shz-icon shz-icon-chevron-left shz-icon-size-md"></i>',
            rightArrow: '<i class="shz-icon shz-icon-chevron-right shz-icon-size-md"></i>'
        }
    });

    // Funkcionalita pre tlačidlo "Poslať emailom"
    $('[data-send-mail-button]').click(function() {
        var emailLink = 'mailto:?subject=Predmet emailu&body=' + encodeURIComponent(window.location.href);
        window.location.href = emailLink;
    });

    // Funkcionalita pre tlačidlo "Tlačiť"
    $('[data-print-button]').click(function() {
        window.print();
    });

    $('[data-facebook-button]').click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        var currentUrl = $(this).attr('href');
        var facebookShareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(currentUrl);
        window.open(facebookShareUrl, "_blank", "width=600,height=400");
    });

    // Funkcionalita pre tlačidlo "Kopírovať odkaz"
    $('[data-copy-button]').click(function() {
        var copyText = window.location.href;
        var tempInput = $('<input>');
        $('body').append(tempInput);
        tempInput.val(copyText).select();
        document.execCommand('copy');
        tempInput.remove();
        //alert('Odkaz bol skopírovaný.');
    });

    $(document).on("click", "[data-load-more-posts]", function (e) {
        e.preventDefault();
        var $self = $(this);

        var xhr = new XMLHttpRequest();
        xhr.open("GET", $self.attr("href"), true);
        xhr.onload = function () {
            document.querySelector("[data-load-more-posts]").parentElement.remove();

            if (xhr.status >= 200 && xhr.status < 400) {
                var response = xhr.responseText;

                if (response && response.length > 10) {
                    document.querySelector("[data-posts-content]").innerHTML += response;
                }
            }
        };
        xhr.send();
    });

    $(document).on('submit', '[data-validate-form]', function (e) {
        let validations = [];

        const elementsToValidated = $(this).find('[data-validation]');

        if (elementsToValidated.length > 0) {
            elementsToValidated.each(function (inputKey, inputField) {
                validations = [...validations, ...validateFormField($(inputField))];
            });
        }

        if (validations.length > 0) {
            e.stopPropagation();
            e.preventDefault();

            $('html, body').animate({
                scrollTop: $(this).find('.error').first().offset().top - 60
            }, 1000);
        } else {
            return true;
        }
    });

    $(document).on('focusout', "[data-validation]", function (e) {
        validateFormField($(this));
    });

    $(document).on('change', "[data-validation]", function (e) {
        validateFormField($(this));
    });

    $(document).on('change', "[name=\"check_parent\"]", function(e) {
        if ($(this).prop('checked')) {
            $(this).closest('form').find('[data-unchecked-parent]').addClass('d-none');
            $(this).closest('form').find('[data-checked-parent]').removeClass('d-none');
        } else {
            $(this).closest('form').find('[data-unchecked-parent]').removeClass('d-none');
            $(this).closest('form').find('[data-checked-parent]').addClass('d-none');
        }
    });

    if ($(document).find("[name=\"check_parent\"]").length > 0) {
       $(document).find("[name=\"check_parent\"]").each(function () {
           if ($(this).prop('checked')) {
               $(this).closest('form').find('[data-unchecked-parent]').addClass('d-none');
               $(this).closest('form').find('[data-checked-parent]').removeClass('d-none');
           } else {
               $(this).closest('form').find('[data-unchecked-parent]').removeClass('d-none');
               $(this).closest('form').find('[data-checked-parent]').addClass('d-none');
           }
       });
    }

    $(document).on('change', "[name=\"prophylaxis\"]", function(e) {
        if ($(this).prop('checked')) {
            $('[data-checked-prophylaxis]').removeClass('d-none');
        } else {
            $('[data-checked-prophylaxis]').addClass('d-none');
        }
    });

    $(document).on('change', '[data-event-autocomplete="event_user"]', function(e) {
       const userDataJson = $(this).find('option[value="' + $(this).val() + '"]').attr('data-user');
       let userData = {};

       if (userDataJson && userDataJson.length > 0) {
           try {
               userData = JSON.parse(userDataJson);
           } catch (e) {}
       }

       fillEventForm($(this).closest('form'), userData);
       $(this).closest('.input-group').find('.dropdown2-items').removeClass('open');
    });

    function fillEventForm(form, userData) {
        form.find('input').each((inputElKey, inputEl) => {
            if ($(inputEl).attr('type') == 'text') {
                $(inputEl).val('')
            } else if ($(inputEl).attr('type') == 'checkbox') {
                $(inputEl).prop('checked', false);
            }

            const fieldName = $(inputEl).attr('name');
            console.log('????', userData);
            if (userData[fieldName]) {
                if (fieldName === 'birthday' || fieldName == 'birthday_parent') {
                    $(inputEl).val(transformToUIDate(userData[fieldName]));

                    if (!isAdult(userData[fieldName])) {
                        form.find('[name="check_parent"]').prop('checked', true).trigger('change');
                    }
                } else if ($(inputEl).attr('type') === 'text') {
                    $(inputEl).val(userData[fieldName]);
                } else if ($(inputEl).attr('type') === 'checkbox' && !['condition_accept', 'conditionsShz'].includes(fieldName)) { // we dont check other checkboxes
                    $(inputEl).prop('checked', true);
                }
            }

            if (fieldName === 'name_title' && userData.title) {
                $(inputEl).val(userData.title);
            }

            if (fieldName === 'name_and_lastname') {
                const namePart = [];
                if (userData.name) {
                    namePart.push(userData.name);
                }

                if (userData.last_name) {
                    namePart.push(userData.last_name);
                }

                $(inputEl).val(namePart.join(' '));
            }
        });
    }

    function isAdult(yMdDate) {
        var parts = yMdDate.split("-");
        var yearOfBirth = parseInt(parts[0], 10);
        var monthOfBirth = parseInt(parts[1], 10);
        var dayOfBirth = parseInt(parts[2], 10);

        var today = new Date();
        var age = today.getFullYear() - yearOfBirth;

        // Check if the birthday has already occurred this year
        if (
            monthOfBirth > today.getMonth() + 1 ||
            (monthOfBirth === today.getMonth() + 1 && dayOfBirth > today.getDate())
        ) {
            age--;
        }

        return age >= 18;
    }

    function transformToUIDate(datum) {
        var parts = datum.split("-");
        var dayPart = parseInt(parts[2], 10);
        var monthPart = parseInt(parts[1], 10);
        var yearPart = parseInt(parts[0], 10);

        var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        var jnDate = new Date(yearPart, monthPart - 1, dayPart).toLocaleDateString(undefined, options).replaceAll(' ', '');
        return jnDate;
    }

    function validateFormField(field) {
        let parentEl = null;
        let validationFieldError = [];

        if (field.closest('.form-check').length == 1) {
            parentEl = field.closest('.form-check');
        } else if (field.closest('.form-group').length == 1) {
            parentEl = field.closest('.form-group');
        }

        const formEl = field.closest('form');

        if (parentEl) {
            parentEl.removeClass('error');
            parentEl.find('[data-error-msg]').remove();

            const validationAttr = field.attr('data-validation');
            const validationObject = validationAttr ? JSON.parse(validationAttr) : null;
            if (field.is('input[type="checkbox"]')) {
                validationFieldError = validateField(validationObject, field.prop('checked'), formEl);
            } else {
                validationFieldError = validateField(validationObject, field.val(), formEl);
            }

            if (validationFieldError && validationFieldError.length > 0) {
                parentEl.addClass('error');
                parentEl.append('<small class="form-text" data-error-msg>' + validationFieldError.join('<br />') + '</small>');
            }
        }

        return validationFieldError;
    }

    function validateField(validation, value, form) {
        const validationErrors = [];

        if (validation) {
            for (const rule in validation) {
                if (validation.hasOwnProperty(rule)) {
                    const errorMessage = validation[rule];

                    switch (rule) {
                        case 'checked':
                            if (!value) {
                                validationErrors.push(errorMessage);
                            }
                            break;
                        case 'required':
                            if (!value.trim()) {
                                validationErrors.push(errorMessage);
                            }
                            break;
                        case 'required_if_kid':
                            if (form.find('[name="check_parent"]').prop('checked')) {
                                if (!value.trim()) {
                                    validationErrors.push(errorMessage);
                                }
                            }
                            break;
                        case 'min_10_length':
                            if (!value || value.trim().length < 10) {
                                validationErrors.push(errorMessage);
                            }
                            break;
                        case 'email':
                            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                            if (!emailPattern.test(value)) {
                                validationErrors.push(errorMessage);
                            }
                            break;
                        case 'postcode':
                            if (value && value.length > 0) {
                                var cleanPostalCode = value.replace(/\s/g, '');
                                var postalCodePattern = /^\d{5}$/;

                                if (!postalCodePattern.test(cleanPostalCode)) {
                                    validationErrors.push(errorMessage);
                                }
                            }
                        break;
                        case 'phone':
                            if (value && value.length > 0) {
                                // Odstránenie medzier
                                var cleanPhoneNumber = value.replace(/ /g, '');

                                // Regulárny výraz pre akékoľvek telefónne číslo (obsahuje aspoň jedno číslo)
                                var phoneNumberPattern = /\d+/;

                                // Kontrola minimálnej a maximálnej dĺžky
                                var phoneNumberLength = cleanPhoneNumber.length;
                                if (phoneNumberLength > 14 || phoneNumberLength < 9) {
                                    validationErrors.push(errorMessage);
                                } else if (!phoneNumberPattern.test(cleanPhoneNumber)) {
                                    validationErrors.push(errorMessage);
                                }
                            }
                            break;
                        case 'username':
                            const usernamePattern = /^[a-zA-Z0-9\-_.]+$/;
                            if (value && value.trim().length < 3) {
                                validationErrors.push(errorMessage);
                            } else if (value && !usernamePattern.test(value)) {
                                validationErrors.push(errorMessage);
                            }
                            break;
                        case 'password':
                            if (value.length < 8) {
                                validationErrors.push(errorMessage);
                            }
                            break;
                        case 'date_if_kid':
                            if (form.find('[name="check_parent"]').prop('checked')) {
                                if (value && value.length > 0) {
                                    var datePattern = /^(\d{1,2}\.\d{1,2}\.\d{4})|(\d{1,2}\.\d{1,2}\.\d{4})$/;

                                    if (!datePattern.test(value)) {
                                        validationErrors.push(errorMessage);
                                    } else {
                                        var parts = value.split('.');

                                        var day = parseInt(parts[0]);
                                        var month = parseInt(parts[1]);
                                        var year = parseInt(parts[2]);

                                        if (isNaN(day) || isNaN(month) || isNaN(year)) {
                                            validationErrors.push(errorMessage);
                                        } else if ((month < 1 || month > 12) || (day < 1 || day > 31) || (year < 1900)) {
                                            validationErrors.push(errorMessage);
                                        } else {
                                            var date = new Date(year, month - 1, day);
                                            if (isNaN(date.getTime())) {
                                                validationErrors.push(errorMessage);
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        case 'date':
                            if (value && value.length > 0) {
                                var datePattern = /^(\d{1,2}\.\d{1,2}\.\d{4})|(\d{1,2}\.\d{1,2}\.\d{4})$/;

                                if (!datePattern.test(value)) {
                                    validationErrors.push(errorMessage);
                                } else {
                                    var parts = value.split('.');

                                    var day = parseInt(parts[0]);
                                    var month = parseInt(parts[1]);
                                    var year = parseInt(parts[2]);

                                    if (isNaN(day) || isNaN(month) || isNaN(year)) {
                                        validationErrors.push(errorMessage);
                                    } else if ((month < 1 || month > 12) || (day < 1 || day > 31) || (year < 1900)) {
                                        validationErrors.push(errorMessage);
                                    } else {
                                        var date = new Date(year, month - 1, day);
                                        if (isNaN(date.getTime())) {
                                            validationErrors.push(errorMessage);
                                        }
                                    }
                                }
                            }
                            break;
                        case 'password_reply':
                            const compareValue = form.find('[name="password"]').val();
                            if (value !== compareValue) {
                                validationErrors.push(errorMessage);
                            }
                            break;
                    }
                }
            }
        }

        return validationErrors;
    }
});

function submitFormAfterCaptcha(token) {
    const formEl = document.querySelector('[data-validate-form]');
    if (formEl) {
        $(formEl).submit();
    }
}
